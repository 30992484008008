<template>
    <v-container class="pa-0" fluid>
        <!-- Loading spinner -->
        <div v-if="loading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <v-card v-if="!loading" class="transparent" outlined>
            <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="mb-4 elevation-3">
                <span class="primary--text">{{ this.startShift + ' - ' + this.endShift}}</span>

                <v-spacer/>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="loadingPrinting" :loading="loadingPrinting" @click="printShiftReportMonth" color="primary" icon v-on="on">
                            <v-icon large>print</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('generic.lang_reprint')}}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="loadingDownload" :loading="loadingDownload" @click="downloadMonthlyReportPDF" color="warning" icon v-on="on">
                            <v-icon large>cloud_download</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('generic.lang_download')}}</span>
                </v-tooltip>
            </v-card-title>

            <v-divider />

            <v-card-subtitle>
                <v-row class="ma-0 pa-0 text-center">
                    <v-col class="row mx-auto text-center" cols="12">
                        <v-col cols="12" md="4">
                            <v-card class="cards text-center" elevation="6">
                                <v-card-title class="pt-6 pb-6">
                                    <span class="mx-auto bolds">{{this.total | currency}}</span>
                                </v-card-title>
                                <v-divider class="pa-0 ma-0"/>
                                <v-card-subtitle class="ma-0 pa-1">
                                    <strong>{{$t('generic.lang_total')}}</strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-card class="cards text-center" elevation="6">
                                <v-card-title class="pt-6 pb-6">
                                    <span class="mx-auto bolds">{{this.subtotal | currency}}</span>
                                </v-card-title>
                                <v-divider class="pa-0 ma-0"/>
                                <v-card-subtitle class="ma-0 pa-1">
                                    <strong>{{$t('generic.lang_subtotal')}}</strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-card class="cards text-center" elevation="6">
                                <v-card-title class="pt-6 pb-6">
                                    <span class="mx-auto bolds">{{this.tax | currency}}</span>
                                </v-card-title>
                                <v-divider class="pa-0 ma-0"/>
                                <v-card-subtitle class="ma-0 pa-1">
                                    <strong>{{$t('accounting.lang_taxes')}}</strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-subtitle>

            <v-card-text :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="pa-0 elevation-6">
                <v-tabs v-model="tabs">
                    <v-tab>
                        {{$t('accounting.lang_salesStatistics')}}
                    </v-tab>
                    <v-tab>{{$t('accounting.lang_salesByItemgroup')}}</v-tab>
                    <v-tab>{{$t('accounting.lang_userSalesStats')}}</v-tab>
                    <v-tab>{{$t('accounting.lang_kassenbuch')}}</v-tab>

                </v-tabs>

                <v-container class="pa-0 ma-0" fluid>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" v-if="this.tabs == 0">
                            <umsatz-component/>
                        </v-col>

                        <v-col cols="12" v-if="this.tabs == 1">
                            <umsatz-ware-group-component/>
                        </v-col>


                        <v-col cols="12" v-if="this.tabs == 2">
                            <umsatz-cashier-component/>
                        </v-col>

                        <v-col cols="12" v-if="this.tabs == 3">
                            <cash-book-component/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <!-- MONTHLY REPORT PDF DIALOG -->
        <v-dialog v-model="displayMonthlyReportDialog" persistent width="600">
            <v-card>
                <v-card-text style="height: 600px; padding: 0;">
                    <iframe v-if="iframePDFContent.length > 0" :src="iframePDFContent" width="600" height="550"/>
                </v-card-text>

                <v-card-actions style="background-color: white;">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" dark text @click="displayMonthlyReportDialog = false">{{ $t('generic.lang_close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../config";
    import UmsatzWareGroupComponent from "./UmsatzWareGroupComponent";
    import UmsatzComponent from "./UmsatzComponent";
    import UmsatzCashierComponent from "./UmsatzCashierComponent";
    import CashBookComponent from "./CashBookComponent";
    import {Events} from "../../../plugins/events";
    import {mapGetters, mapState} from "vuex";
    import {printDataFromPrinter} from "../../../plugins/printing/printerController";

    export default {
        name: "MonthlyReportDetailsComponent",

        components: {
            UmsatzWareGroupComponent,
            UmsatzComponent,
            UmsatzCashierComponent,
            CashBookComponent
        },

        computed: {
            ...mapState([
                'pos',
                'api',
                'tse'
            ]),
            ...mapState('printer', {
                printers: state => state.printers
            }),
            ...mapGetters({
                fiscalClient: 'tse/fiscalClient'
            }),
            invoicePrinter() {
                return this.printers.find((printer) => {
                    if(printer.cashierID.includes(this.api.auth.cashierID)) {
                        if(printer.type === 1) {
                            return true;
                        }
                    }
                });
            }
        },

        data: () => {
            return {
                ENDPOINTS,
                loading: false,
                loadingDownload: false,
                loadingPrinting: false,
                startShift: "",
                endShift: "",
                total: "",
                subtotal: "",
                gross: "",
                tax: "",
                dialog: false,
                tabs: null,
                iframePDFContent: "",
                displayMonthlyReportDialog: false,
            }
        },

        methods: {
            loadData() {
                this.loading = true;

                this.axios.post(ENDPOINTS.ACCOUNTING.MONTHLYREPORT.GET, {
                    reportID: this.$route.params.id
                }).then((res) => {
                    if (res.data.success) {
                        this.startShift = res.data.shifts.startShift;
                        this.endShift = res.data.shifts.endShift;
                        this.subtotal = res.data.netTotal;
                        this.total = res.data.total;
                        this.tax = res.data.tax;

                        this.loading = false;
                    }
                });
            },
            downloadMonthlyReportPDF() {
                this.loadingDownload = true;

                this.axios.post(ENDPOINTS.ACCOUNTING.MONTHLYREPORT.PRINTA4, {
                    reportID: this.$route.params.id
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        this.axios.post(process.env.VUE_APP_PDF_SERVER_HOST + '/template', {
                                "body": {
                                    html: res.data.template,
                                    payload: res.data.values,
                                }
                            },
                            {
                                responseType: 'arraybuffer',
                                headers: {
                                    'POSID': this.api.auth.posID,
                                    'ACCESSSTRING': this.api.auth.accessString,
                                    'APITOKEN': this.api.auth.apiWebToken,
                                }
                            }
                        ).then((res) => {
                            //GENERATE PDF CONTENT FOR iFRAME
                            const blobContent = new Blob([res.data], {type: "application/pdf"});
                            this.iframePDFContent = URL.createObjectURL(blobContent);

                            this.displayMonthlyReportDialog = true;

                            //DOWNLOAD INVOICE AS PDF
                            FileSaver.saveAs(new Blob([res.data], {
                                type: "application/pdf"
                            }), "Monatsabschluss.pdf");

                            this.loadingDownload = false;
                        }).catch(() => {
                            this.loadingDownload = false;
                        });
                    }
                });

            },
            printShiftReportMonth(){
                this.loadingPrinting = true;

                this.axios.post(ENDPOINTS.ACCOUNTING.MONTHLYREPORT.PRINT, {
                    reportID: this.$route.params.id
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                      printDataFromPrinter(this.invoicePrinter,res.data.printXML).then(()=>{

                      }).catch((err)=>{
                        Events.$emit("showSnackbar", {
                          message: this.$t('accounting.lang_monthlyStatementCouldNotBePrinted'),
                          color: "error"
                        });
                      }).finally(()=>{
                        this.loadingPrinting = false;
                      })

                    } else {
                        this.loadingPrinting = false;

                        Events.$emit("showSnackbar", {
                            message: this.$t('accounting.lang_monthlyStatementCouldNotBePrinted'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    this.loadingPrinting = false;

                    Events.$emit("showSnackbar", {
                        message: this.$t('accounting.lang_monthlyStatementCouldNotBePrinted'),
                        color: "error"
                    });
                });
            }
        },

      mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .bolds {
        font-size: 30px;
    }

    #cards {
        max-width: 200px !important;
    }

    #showReport_iframe {
        width: 100% !important;
        height: 100% !important;
        border: none;
    }

    #pdf_card {
        width: 100% !important;
        height: 100% !important;
    }
</style>